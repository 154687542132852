<template>
  <div class="transit-block">
    <div class="business-trip__expenses-row">
      <div class="business-trip__expenses-col" v-show="false">
        <div class="business-trip__expenses-fieldset">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <esmp-select
              placeholder="Территориальная зона"
              filterable
              clearable
              :value="fields.zone ? fields.zone.id : null"
              @input="
                update({
                  field: 'zone',
                  value: $event,
                  list: territorialZones,
                })
              "
            >
              <esmp-select-option
                v-for="item in territorialZones"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <validation-provider
              :name="`Место назначения ${(currentIndex + 1)}`"
              rules="required|city"
              v-slot="{ errors }"
            >
              <destination-component
                block="accommodation"
                :index="currentIndex"
                @input="setZone"
                :is-valid="true"
                :value="fields.destination"
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <validation-provider
              :name="`Отель ${(currentIndex + 1)}`"
              rules=""
              v-slot="{ errors }"
            >
              <hotel
                :city="
                  fields.destination ? fields.destination : null
                "
                :is-valid="true"
                v-model="fields.hotel"
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div class="business-trip__expenses-field">
            <validation-provider
              :name="`Заезд ${(currentIndex + 1)}`"
              :rules="{ required: true, datePeriod: { endDay: fields.dayEnd } }"
              v-slot="{ errors }"
            >
              <esmp-datepicker-adaptive
                placeholder="Заезд"
                v-model="fields.dayStart"
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
          <div class="business-trip__expenses-field">
            <validation-provider
              :name="`Выезд ${(currentIndex + 1)}`"
              :rules="{ required: true, datePeriod: { startDay: fields.dayStart } }"
              v-slot="{ errors }"
            >
              <esmp-datepicker-adaptive
                placeholder="Выезд"
                v-model="fields.dayEnd"
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="business-trip__expenses-col">
        <esmp-checkbox v-model="fields.requireMatch">
          Требуется подбор
        </esmp-checkbox>
      </div>

      <div class="business-trip__expenses-col" v-if="isAvailableRemove">
        <action-btn
          :is-remove="true"
          @click="removeAccommodation"
        >
          Удалить отель
        </action-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import DestinationComponent from '@/components/business-trip/destination/destination-component.vue';
import { businessTrip } from '@/constants/businessTrip';
import ActionBtn from './action-btn/action-btn.vue';
import Hotel from './hotel.vue';

export default {
  name: 'AccommodationBlock',
  props: {
    fields: {
      type: Object,
      default: undefined,
    },
    isEdit: Boolean,
    currentIndex: {
      type: [Number, String],
      default: undefined,
    },
    isAvailableRemove: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('businessTrip', ['territorialZones']),
    numberIndex() {
      return Number(this.currentIndex);
    },
  },
  methods: {
    update({ field, value, list }) {
      let val = value;
      if (list && list.length) {
        val = list.find((i) => i.id === value);
      }
      this.fields[field] = val;
    },
    removeAccommodation() {
      this.$emit('remove', this.numberIndex);
    },
    setZone() {
      if (!this.fields.destination) return;
      let zone = null;
      // eslint-disable-next-line no-nested-ternary
      const getDestination = (block) => {
        if (isEmpty(block) || !block.region) return null;
        if (block.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.region;
        }
        if (block.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.area;
        }

        return isEmpty(block.settlement) ? block.city : block.settlement;
      };
      const r = getDestination(this.fields.destination);

      if (r) {
        zone = this.territorialZones.find((item) => item.id === r.zoneCode) || null;
      }

      this.fields.zone = zone;
    },
  },
  components: {
    ActionBtn,
    DestinationComponent,
    Hotel,
  },
};
</script>

<style scoped>
@import '~@/assets/scss/blocks/business-trip.scss';
</style>
